import React, { createContext, useState, useEffect } from 'react';
    
const SessionContext = createContext(null);
    
const SessionProvider = ({ children }) => {
      const [sessionData, setSessionData] = useState(() => {
        const storedData = sessionStorage.getItem('sessionData');
        return storedData ? JSON.parse(storedData) : {};
      });
    
      useEffect(() => {
        sessionStorage.setItem('sessionData', JSON.stringify(sessionData));
      }, [sessionData]);
    
      return (
        <SessionContext.Provider value={{ sessionData, setSessionData }}>
          {children}
        </SessionContext.Provider>
      );
};
    
export { SessionContext, SessionProvider };