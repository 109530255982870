import React from 'react'

const PharmacistIcon = () => (
    <svg width="30" height="24" viewBox="0 0 42 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  
     <path d="M10.5 12C13.8141 12 16.5 9.31406 16.5 6C16.5 2.68594 13.8141 0 10.5 0C7.18594 0 4.5 2.68594 4.5 6C4.5 9.31406 7.18594 12 10.5 12ZM4.875 19.875C4.875 20.4984 5.37656 21 6 21C6.62344 21 7.125 20.4984 7.125 19.875C7.125 19.2516 6.62344 18.75 6 18.75C5.37656 18.75 4.875 19.2516 4.875 19.875ZM15 13.5281V15.825C16.7109 16.1719 18 17.6906 18 19.5V21.4547C18 21.8109 17.7469 22.1203 17.3953 22.1906L15.8859 22.4906C15.6844 22.5328 15.4875 22.4016 15.4453 22.1953L15.3 21.4594C15.2578 21.2578 15.3891 21.0562 15.5953 21.0187L16.5 20.8359V19.5C16.5 16.5563 12 16.4484 12 19.5891V20.8406L12.9047 21.0234C13.1062 21.0656 13.2375 21.2625 13.2 21.4641L13.0547 22.2C13.0125 22.4016 12.8156 22.5328 12.6141 22.4953L11.1516 22.2984C10.7812 22.2469 10.5047 21.9328 10.5047 21.5531V19.5C10.5047 17.6906 11.7938 16.1766 13.5047 15.825V13.7062C13.4016 13.7391 13.2984 13.7578 13.1953 13.7953C12.3516 14.0906 11.4469 14.2547 10.5047 14.2547C9.5625 14.2547 8.65781 14.0906 7.81406 13.7953C7.46719 13.6734 7.11563 13.5984 6.75469 13.5516V17.3766C7.8375 17.7 8.62969 18.6938 8.62969 19.8797C8.62969 21.3281 7.45313 22.5047 6.00469 22.5047C4.55625 22.5047 3.37969 21.3281 3.37969 19.8797C3.37969 18.6938 4.17187 17.7 5.25469 17.3766V13.6078C2.27344 14.1094 0 16.6781 0 19.8V21.9C0 23.0578 0.942187 24 2.1 24H18.9C20.0578 24 21 23.0578 21 21.9V19.8C21 16.425 18.3375 13.6922 15 13.5281V13.5281Z" fill="white"/>
<path d="M40.5317 15.8319C40.8738 14.527 40.6873 13.1658 40.0059 12.0023C39.4943 11.1265 38.7435 10.4337 37.8326 9.99691C36.3157 9.26953 34.545 9.34204 33.0954 10.1882L25.0999 14.8639C23.9347 15.5454 23.105 16.6389 22.763 17.9454C22.4209 19.2503 22.6077 20.6108 23.2891 21.7744C23.7997 22.6505 24.5515 23.3429 25.4624 23.7797C26.9786 24.5068 28.7493 24.4343 30.1986 23.5871L38.1935 18.9127C39.3607 18.2306 40.1893 17.1374 40.5317 15.8319ZM37.141 13.0539C37.0367 12.9715 36.1445 12.3116 34.7355 13.1339L32.6154 14.3739C32.3642 14.5196 32.069 14.5228 31.8249 14.4057C31.6782 14.3353 31.5502 14.2224 31.4631 14.0709C31.228 13.6695 31.3638 13.154 31.7651 12.9189L33.8849 11.6797C36.4 10.2091 38.1666 11.715 38.2401 11.7802C38.5903 12.087 38.6259 12.6182 38.3194 12.9677C38.0162 13.3139 37.4901 13.3494 37.141 13.0539ZM29.3507 22.1325C28.3842 22.698 27.2022 22.7467 26.1905 22.2615C25.5845 21.9709 25.0831 21.5076 24.7427 20.9251C24.2888 20.1493 24.1638 19.2427 24.3919 18.3714C24.6198 17.5008 25.1736 16.7725 25.9491 16.3192L29.14 14.4524C29.6138 14.1755 30.2236 14.3349 30.5018 14.8093L32.8972 18.9049C33.0311 19.1322 33.0678 19.4043 33.0012 19.6593C32.9333 19.9154 32.7692 20.1328 32.5412 20.2664L29.3507 22.1325Z" fill="white"/>
<path d="M31.3641 7.25957C31.4061 4.39832 29.1216 2.04407 26.2596 2.00057C23.3983 1.95857 21.0433 4.24307 21.0006 7.10582C20.9578 9.96707 23.2431 12.3213 26.1051 12.3626C28.9671 12.4061 31.3213 10.1223 31.3641 7.25957ZM29.0841 8.10332C29.0773 8.10332 29.0691 8.10332 29.0616 8.10332L23.2596 7.95032C22.7953 7.93757 22.4278 7.55057 22.4406 7.08482C22.4533 6.62132 22.8208 6.25907 23.3038 6.26582L29.1058 6.41732C29.5701 6.42932 29.9368 6.81632 29.9248 7.28132C29.9121 7.73957 29.5386 8.10332 29.0841 8.10332Z" fill="white"/>
 
    </svg>
    
)

export default PharmacistIcon
