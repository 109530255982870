import React from 'react'
import { TabsContainer } from './Registration.styles'
import LogoImage from '../../assets/images/OrigoHealthLogo2.png'
import { Button } from 'react-bootstrap'

const RegistrationTabs = ({ activeTab, setActiveTab }) => {
  return (
    <TabsContainer>
    	<img
    		src={LogoImage} 
        alt='cloud logo'
    		style={{
          height: '150px',
          width: '150px',
          objectFit: 'contain'
        }}
    	/>	
      {/* <SubTitle color="black">Sign up as a patient or provider</SubTitle> */}

      <div className="tabs-wrapper">
        <Button
          color="euphoria"
          size="large"
          disableElevation
          variant={activeTab === 'Patients' ? 'contained' : 'outlined'}
          onClick={() => {
            setActiveTab('Patients')
          }}
        >
          Patients
        </Button>
        &nbsp; &nbsp;
        {/*
        <Button
          color="euphoria"
          size="large"
          variant={activeTab === 'Doctors and Care Providers' ? 'contained' : 'outlined'}
          disableElevation
          onClick={() => {
            setActiveTab('Doctors and Care Providers')
          }}
        >
          Doctors and Care Providers
        </Button>
        */}
        &nbsp; &nbsp;
        {/*
        <Button
          color="euphoria"
          size="large"
          variant={activeTab === 'Others/SSPs' ? 'contained' : 'outlined'}
          disableElevation
          onClick={() => {
            setActiveTab('Others/SSPs')
          }}
        >
          Others/SSPs
        </Button>
        */}
      </div>
    </TabsContainer>
  )
}

export default RegistrationTabs
