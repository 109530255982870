import React from 'react'
import ReactDOM from 'react-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { SessionProvider } from './session/SessionProvider'
import App from './App'
import history from './utils/history'
import { globalConfig } from './config'
import * as serviceWorker from './serviceWorker'

import './assets/styles/app.scss'

const onRedirectCallback = appState => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  )
}

ReactDOM.render(
  <Auth0Provider
    domain={globalConfig.auth0.domain}
    clientId={globalConfig.auth0.clientId}
    audience={`https://${globalConfig.auth0.domain}/api/v2/`}
    scope={'read:current_user read:current_user_metadata'}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
  	<SessionProvider>
    <App />
  	</SessionProvider>
  </Auth0Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
