import React from 'react'
import { Formik } from 'formik'
import { FormWrapper } from '../Form.styles'
import { validationSchema } from './validationSchema'
import { useToasts } from 'react-toast-notifications'
import SupplementalServiceProviderDetails from './SupplementalServiceProviderDetails'
import { API_PATHS, instance } from '../../../../services'
import { globalConfig } from '../../../../config'

const SupplementalServiceProviderSignup = ({ loginWithRedirect }) => {
  const { addToast } = useToasts()

  const onSubmitRegistration = async values => {
    try {
      await instance.post(
        `${API_PATHS.mainPath}/user-reg-service/register-ehx-ssp/${globalConfig.cloudName}`,
        {
          ...values,
          cloudName: `${globalConfig.cloudName}`,
        }
      )
      addToast('Registration complete', {
        autoDismiss: true,
        appearance: 'success',
      })
      return loginWithRedirect();
    } catch (error) {
//    	console.log('Error object -> ' + error.response.data.errors.length);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach(el => {
          addToast(el.msg, {
            autoDismiss: true,
            appearance: 'error',
          })
        })
      } 
// else {
//    	console.log('Error object 2 -> ' + JSON.stringify(error.response.data));
//        addToast(error?.response?.data?.message, {
//          autoDismiss: true,
//          appearance: 'error',
//        })
//      }
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          firstName: '',
          middleName: '',
          lastName: '',
          mobilePhone: '',
          address: '',
          address2: '',
          state: '',
          country: '',
          postalCode: '',
          dateOfBirth: '',
          email: '',
          password: '',
          providerType: '',
          facilityName: '',
          cloudName: `${globalConfig.cloudName}`,
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          onSubmitRegistration(values)
        }}
      >
        <>
          <FormWrapper style={{ paddingTop: '0' }}>
            <SupplementalServiceProviderDetails />
          </FormWrapper>
        </>
      </Formik>
    </>
  )
}

export default SupplementalServiceProviderSignup
