import React, { useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Auth } from './components/Auth/Auth'
import { Loading } from './components/Loading/Loading'
import Registration from './components/Registration/Registration'
import SubmitVitalSigns from './components/Registration/SubmitVitalSigns'
import ErrorBoundary from './components/ErrorBoundary'
import MainDashBoard from './components/MainDashboard/MainDashboard'
import { SessionContext } from './session/SessionProvider'
import { REGISTER_PATIENT, SUBMIT_VITAL_SIGNS } from './components/NavigationComponent/constants'

// styles
import { createTheme, ThemeProvider } from '@mui/material/styles'
import 'bootstrap/dist/css/bootstrap.min.css'
import './components/DashboardContent/patient-table.css';

const theme = createTheme({
  palette: {
    euphoria: {
      light: '#50cb92',
      main: '#25be77',
      dark: '#198553',
      contrastText: '#fff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        focusVisible: false,
      },
    },
  },
})

function BaseApp({ loginWithRedirect }) {
  const location = useLocation();
  return (
    <>
      {location.pathname === REGISTER_PATIENT ? (
        <Registration loginWithRedirect={loginWithRedirect}/>
      ): 
		((location.pathname === SUBMIT_VITAL_SIGNS) ? (<SubmitVitalSigns loginWithRedirect={loginWithRedirect} />)
			: (loginWithRedirect()))		      
      }
    </>
  )
}

function App() {
  	const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
	const { sessionData, setSessionData} = useContext(SessionContext);
	
	const updateSession = (newData) => {
		setSessionData({...sessionData, ...newData });
	}
	
	//updateSession({currentPatient : {ehxPatientId : 5, patientName : 'Patty Pattison'}});
	
 	const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 5000,
      },
    },
  })

//             	<SessionProvider>
// 				</SessionProvider>
  return (
    <>
      <ThemeProvider theme={theme}>
        <ToastProvider placement="bottom-left">
          {isLoading ? (
            <Loading />
          ) : (
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                {isAuthenticated ? (
                  <Auth>
                      <ErrorBoundary>
                        <MainDashBoard />
                      </ErrorBoundary>
                  </Auth>
                ) : (
                  <BaseApp loginWithRedirect={loginWithRedirect}/>
                )}
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
              	
            </BrowserRouter>
          )}
        </ToastProvider>
      </ThemeProvider>
    </>
  )
}

export default App
