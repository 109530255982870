import React from 'react'
//import Signup from './Form/Patient/Signup'
import {
  SubTitle,
  Info,
  FormContainer,
  LoginBtn,
  TitleContainer,
  TabPanel,
  LogoImage,
} from './Registration.styles'
import { useState } from 'react'
import {
  InputAdornment,
  IconButton,
  TextField,
  Container,
  MenuItem,
  Tabs,
  Tab,
  Typography,
  Button,
  Link
} from '@mui/material'
import VitalSignsModel from './Model/VitalSignsModel';
// import FormTabs from './RegistrationTabs'
// import ProviderSignup from './Form/Provider/ProviderSignup'
// import SupplementalServiceProviderSignup from './Form/Provider/SupplementalServiceProviderSignup'

const SubmitVitalSigns = ({ user, loginWithRedirect }) => {

	const [vitalSigns,setVitalSigns] = useState(VitalSignsModel);
//   const [activeTab, setActiveTab] = useState('Patients')

	const	valueChangeHandler = (e, field) => {
		field += e.target.value;
		console.log(field);
		console.log(vitalSigns.systolic);
	}
  return (
  <>
    <Container>
      <Info>
        <TitleContainer>
          <SubTitle>
        	<LogoImage />
            <br />
            Become the best version of you! Already
            have an account? &nbsp; &nbsp;
            <span>
              <LoginBtn onClick={() => loginWithRedirect()}>Login here</LoginBtn>
            </span>
            <br />
          </SubTitle>
        </TitleContainer>
      </Info>
      <br />
      <div>
      <h4>Enter Your Vital Signs</h4>
      </div>
      <div>
      <TextField
      	name="patientId"
      	fullWidth
      	placeholder="Enter Your Patient Number"
      />
      </div>
      <br />
      <div>
      <TextField
      	name="systolic"
      	fullWidth
      	label="Systolic"
      	placeholder="Systolic"
      	value={vitalSigns.systolic}
      	type="number"
      	onChange={(e) => valueChangeHandler(e,vitalSigns.systolic)}
      />
      </div>
      <br />
      <div>
      <TextField
      	name="diastolic"
      	fullWidth
      	label="Diastolic"
      	placeholder="Diastolic"
      	value={vitalSigns.diastolic}
      	type="number"
      />
      </div>
      <br />
      <div>
      <TextField
      	name="pulse"
      	fullWidth
      	label="Pulse"
      	placeholder="Pulse"
      	value={vitalSigns.pulse}
      	type="number"
      />
      </div>
      <br />
      <div>
      <TextField
      	name="temperature"
      	fullWidth
      	label="Temperature"
      	placeholder="Temperature"
      	value={vitalSigns.temperature}
      	type="number"
      />
      </div>
      <br />
      <div>
      <TextField
      	name="sp02"
      	fullWidth
      	label="SP02"
      	placeholder="SP02"
      	value={vitalSigns.sp02}
      	type="number"
      />
      </div>
      <br />
      <div>
      <TextField
      	name="bmi"
      	fullWidth
      	label="BMI"
      	placeholder="BMI"
      	value={vitalSigns.bmi}
      	type="number"
      />
      </div>
      <hr />
      <Link href="#">
      	<Button 
      		variant="contained"
      		color="success"
      	>
      		Save
      	</Button>
      </Link>
      &nbsp;
      <Link href="#">
      	<Button 
      		variant="contained"
      		color="warning"
      	>
      		Add more health measures
      	</Button>
      </Link>
      &nbsp;
      <Link href="#">
      	<Button 
      		variant="contained"
      		color="error"
      	>
      		book an appointment
      	</Button>
      </Link>
      &nbsp;
      <Link href="/register-patient">
      	<Button 
      		variant="contained"
      		color="info"
      	>
      		register
      	</Button>
      </Link>
    </Container>
    </>
  )
}


export default SubmitVitalSigns
